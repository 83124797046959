<template>
<div>
    <section id="knowledge-base-content">
        <app-collapse type="margin">
            <app-collapse-item title="Sistem Raporları">
                <b-row class="kb-search-content-info match-height">
                    <b-col md="4" sm="6" class="kb-search-content" v-for="element in menu.filter(x=>x.type=='system')" :key="element.router">
                        <b-card class="position-static text-center bg-primary text-white" img-alt="Test" img-top @click="$router.push({ name: element.router })">
                            <b-card-body>
                                <blockquote class="blockquote  mb-0">
                                    <p>{{element.title}}</p>
                                    <footer class="blockquote-footer text-white opacity-75">
                                        <small>{{element.definition}}</small>
                                    </footer>
                                </blockquote>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </app-collapse-item>
            <app-collapse-item title="Öğrenci Raporları">
                <b-row class="kb-search-content-info match-height">
                    <b-col md="4" sm="6" class="kb-search-content" v-for="element in menu.filter(x=>x.type=='user')" :key="element.router">
                        <b-card class="position-static text-center bg-primary text-white" img-alt="Test" img-top @click="$router.push({ name: element.router })">
                            <b-card-body>
                                <blockquote class="blockquote  mb-0">
                                    <p>{{element.title}}</p>
                                    <footer class="blockquote-footer text-white opacity-75">
                                        <small>{{element.definition}}</small>
                                    </footer>
                                </blockquote>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </app-collapse-item>
        </app-collapse>
    </section>
</div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BForm,
    BButton,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BSidebar,
    BListGroupItem
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
    components: {
        AppCollapse,
        AppCollapseItem,
        ToastificationContent,
        BRow,
        BCol,
        BCard,
        BCardBody,
        BCardText,
        BForm,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BSidebar,
        BListGroupItem,
        BButton
    },
    data() {
        return {
            menu: [{
                    title: 'Kazanım Eşleştirme Raporları',
                    definition: 'Kazanımların Hangi Video ve Sorularla Eşleştiğini Raporlar',
                    router: 'report-gains-page',
                    type: 'system'
                },
                {
                    title: 'Video Raporları',
                    definition: 'Videoların Hangi Kazanım,Bölüm,Soru Raporları',
                    router: 'report-system-video-page',
                    type: 'system'
                },
                {
                    title: 'Video İzleme Raporları',
                    definition: 'Öğrencilerin Hangi Videoları ne kadar İzlediğinin Raporları',
                    router: 'report-video-page',
                    type: 'user'
                },
                {
                    title: 'Öğrenci Eksik Raporları',
                    definition: 'Öğrencilerin Hangi Eksiklerinin Olduğu Raporlar',
                    router: 'report-lack-page',
                    type: 'user'
                },
                {
                    title: 'Öğrenci Test Raporları',
                    definition: 'Öğrencilerin Hangi Testlere Girdiğini Raporlar',
                    router: 'report-test-page',
                    type: 'user'
                },
                {
                    title: 'Ders Başarı Oranları',
                    definition: 'Öğrencilerin Ders Başarı Raporları',
                    router: 'report-percent-page',
                    type: 'user'
                },
                {
                    title: 'Öğrenci Deneme Sınavı Raporları',
                    definition: 'Öğrencilerin Hangi Deneme Sınavına Girdiğini Raporlar',
                    router: 'report-exam-page',
                    type: 'user'
                },
                {
                    title: 'Günlük Test Raporları',
                    definition: 'Öğrencilerin Günlük Çözdüğü Test Raporları',
                    router: 'report-daily-test-page',
                    type: 'user'
                },
                {
                    title: 'Dijital Ödev Raporları',
                    definition: 'Öğrencilerin Ödev Raporları',
                    router: 'report-digital-homework-page',
                    type: 'user'
                },
                {
                    title: 'Öğrenci İzleme',
                    definition: 'Öğrencinin sistem üzerindeki bütün hareketlerini izleme',
                    router: 'report-user-watching',
                    type: 'user'
                },
                {
                    title: 'Cevap Anahtarları',
                    definition: 'Testlerin Cevap Anahtarları',
                    router: 'report-question-page',
                    type: 'user'
                },
                {
                    title: 'Öğrenci Sınıf Listesi',
                    definition: 'Resimli Sınıf Listesi',
                    router: 'report-student-detail-page',
                    type: 'user'
                },
                {
                    title: 'Öğrenci Devam Takip',
                    definition: 'Resimli Sınıf Listesi',
                    router: 'report-user-tracking',
                    type: 'user'
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";
@import '@core/scss/vue/pages/page-knowledge-base.scss';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
